import React, { useEffect, useLayoutEffect, useState } from 'react'
// import Modal from '../../components/common/Modal';
import { addSetting, addUser, callApi, getServices, getSetting, getSettings, getUser, getUsers, setUserActivation, setUserAdministration, updateSetting, updateUser, } from '../../api';
import { PlusCircleIcon, UserIcon, EyeIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast';
import DatePicker, { DateObject } from "react-multi-date-picker";
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { isoToDate } from '../../api/utility';
import moment from 'jalali-moment';
import Modal from '../common/Modal';
// import moment from 'moment-jalaali';


export default function Users() {
    const [users, setUsers] = useState([])
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const [form, setForm] = useState({})
    const [id, setId] = useState(null)
    
    const handler = async () => {
        const { data, error } = await getUsers({ page: currentPage, size: 12 });
        if (data) {
            setUsers(data)
        }
        else {

        }
    }
    const save = async () => {
        // console.info(form)
        // return
        if (id) {
            if (form.password === '')
                delete form.password
            const { data, error } = await updateUser({ ...form, _id: id });
            if (data) {
                setShowModal(false)
            }
            else {
                toast.error(error.response.data.message)
            }
        }
        else {
            if (form.password !== form.re_password) {
                toast.error('رمز عبور با تکرار آن برابر نیست')
                return
            }
            const { data, error } = await addUser(form);
            if (data) {
                setShowModal(false)
            }
            else {
                toast.error(error.response.data.message)
            }
        }
    }

    useEffect(() => {
        if (!showModal) {
            handler()
            setId(null)
            setForm({})
        }
        else {
        }
        return () => {

        }
    }, [currentPage, showModal])

    const getHandler = async (id) => {
        const { data, error } = await getUser(id)
        if (data) {
            setForm(data)
            // setShowModal(true)
        }
    }
    useEffect(() => {



        if (id) {
            getHandler(id)
        }

        return () => {

        }
    }, [id])

    const adminHandler = async (id, t) => {
        const w = toast.loading('صبر کنید')
        await setUserAdministration(id, t)
        await handler()
        toast.dismiss(w)
    }
    const activeHandler = async (id, t) => {
        const w = toast.loading('صبر کنید')
        await setUserActivation(id, t)
        await handler()
        toast.dismiss(w)
    }


    return (
        <>
            <div className="container overflow-x-auto">
                <h1 className=' text-white pb-4 mb-2 h-9 flex items-center justify-start rounded-md'>کاربران</h1>
                <section className='bg-[#cccccc3b] p-5 rounded-md overflow-auto px-5'>
                    <div className='w-full flex items-start mb-4  pb-5'>
                        <button className='text-sm text-white bg-btn rounded-md w-24 p-2 flex flex-row gap-2'
                            onClick={() => setShowModal(true)}
                        >
                            <PlusCircleIcon className='w-5' />
                            افزودن</button>
                    </div>
                    <table className="min-w-full divide-y divide-[#cccccc39] text-right rounded-md">
                        <thead className="text-white   rounded-md">
                            <tr className=' rounded-md'>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    نام و نام خانوادگی
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    نام کاربری
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    ایمیل
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    محدودیت چت
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    تاریخ اعتبار
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    ادمین
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    فعال
                                </th>
                                <th className='w-28'></th>
                                <th className='w-28'></th>
                            </tr>
                        </thead>
                        <tbody className=" divide-y divide-[#cccccc39]">
                            {users?.map((user) => (
                                <tr key={user.id} className='hover:bg-[#cccccc2d] transition-all py-5'>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{user.full_name}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{user.username}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{user.email}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{user.chat_limit}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{isoToDate(user.expiration_time)}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {/* <div className="text-sm text-white mb-2">{user.is_admin ? 'ادمین' : 'کاربر'}</div> */}
                                        <div class="">
                                            <label for={user.id + '_admin'} onClick={e => adminHandler(user.id, !user.is_admin)}>
                                                <input className='text-black' id={user.id + '_admin'} type="checkbox" checked={user.is_admin} />
                                            </label>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {/* <div className="text-sm text-white mb-2">{user.is_active ? 'فعال' : 'غیرفعال'}</div> */}
                                        <div class="">
                                            <label for={user.id + '_active'} onClick={e => activeHandler(user.id, !user.is_active)} >
                                                <input className='text-black' id={user.id + '_active'} type="checkbox" checked={user.is_active} />
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <button className='flex text-sm gap-2 bg-secondary-medium text-white w-24 p-2 rounded-md '
                                            onClick={() => {
                                                setShowModal(true)
                                                setId(user.id)
                                            }}>
                                            <EyeIcon className='w-5' />
                                            مشاهده
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
            <Modal showModal={showModal} className={'w-[40rem]'} setShowModal={setShowModal} title={id ? 'ویرایش' : 'ایجاد'} >
                <div className='mb-4 grid grid-cols-2 gap-2' >

                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>نام و نام خانوادگی</label>
                        <input className='border p-2 bg-input text-input-text text-black rounded-md focus:border-[#191130]' value={form.full_name} onChange={e => setForm(pre => ({ ...pre, full_name: e.target.value }))} />
                    </div>
                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>نام کاربری</label>
                        <input className='border p-2 bg-input text-input-text text-black rounded-md focus:border-[#191130]' value={form.username} onChange={e => setForm(pre => ({ ...pre, username: e.target.value }))} />
                    </div>
                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>ایمیل</label>
                        <input className='border p-2 bg-input text-input-text text-black rounded-md focus:border-[#191130]' value={form.email} onChange={e => setForm(pre => ({ ...pre, email: e.target.value }))} />
                    </div>
                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>رمز عبور</label>
                        <input className='border p-2 bg-input text-input-text text-black rounded-md focus:border-[#191130]' type='password' value={form.password} onChange={e => setForm(pre => ({ ...pre, password: e.target.value }))} />
                    </div>
                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>تکرار رمز عبور</label>
                        <input className='border p-2 bg-input text-input-text text-black rounded-md focus:border-[#191130]' type='password' value={form.re_password} onChange={e => setForm(pre => ({ ...pre, re_password: e.target.value }))} />
                    </div>
                    
                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>نوع کاربری</label>
                        <div className='flex gap-2 w-20 justify-between'>
                            <label htmlFor='admin'>ادمین</label>
                            <input type='radio' name='userType' id='admin' checked={form.is_admin === true} onChange={e => setForm(pre => ({ ...pre, is_admin: true }))} />
                        </div>
                        <div className='flex gap-2 w-20 justify-between'>
                            <label htmlFor='worker'>کاربر</label>
                            <input type='radio' name='userType' id='user' checked={form.is_admin === false} onChange={e => setForm(pre => ({ ...pre, is_admin: false }))} />
                        </div>
                    </div>
                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>نوع کاربری</label>
                        <div className='flex gap-2 w-20 justify-between'>
                            <label htmlFor='admin'>فعال</label>
                            <input type='radio' name='userActivation' id='active' checked={form.is_active === true} onChange={e => setForm(pre => ({ ...pre, is_active: true }))} />
                        </div>
                        <div className='flex gap-2 w-20 justify-between'>
                            <label htmlFor='worker'>غیرفعال</label>
                            <input type='radio' name='userActivation' id='inactive' checked={form.is_active === false} onChange={e => setForm(pre => ({ ...pre, is_active: false }))} />
                        </div>
                    </div>

                </div>
                <button className='float-left bg-btn text-white text-sm w-24 p-2 rounded-md' onClick={save}>ثبت</button>
            </Modal>

        </>
    );
}
