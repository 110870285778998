import { useEffect, useState } from 'react';
import './App.css';
import { selectLogin, setLogin } from './features/login/loginSlice';
import { me } from './api';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './features/user/userSlice';
import Login from './components/Login';
import Loading from './components/common/Loading';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidenav from './components/Sidenav';
import Chat from './components/Chat';
import { Toaster } from 'react-hot-toast';
import Home from './components/Home';
import About from './components/About';
import Users from './components/Users';
import AdminSideNav from './components/Sidenav/AdminSideNav';
import Vector from './components/Vector';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  const login = selectLogin(useSelector(state => state))
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user)

  const callAccount = async e => {
    setIsLoading(true)
    try {
      const { data } = await me()

      if (data) {
        dispatch(setLogin(true))
        dispatch(setUser(data))
      }
      else {
        dispatch(setLogin(false))
      }
      setIsLoading(false)
    }
    catch {
      dispatch(setLogin(false))
      dispatch(setUser(null))
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (localStorage.getItem('token')) {
      callAccount()
    }
    else {
      dispatch(setLogin(false))
      setIsLoading(false)
    }


    return () => {

    }
  }, [login])

  if (isLoading)
    return <section className="" >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Loading />
      </div>
    </section>
  else if (login)
    if (user?.value?.is_admin) {
      return (
        <div className="App font-iransans">
          <div className='grid grid-cols-12 w-screen h-screen gap-4'>
            {/*sidebar*/}
            <Toaster containerStyle={{ position: 'absolute' }} />
            <BrowserRouter>
              <div className='col-span-2'>
                <AdminSideNav />
              </div>
              <div className='col-span-8 col-start-4 mt-[100px]'>

                <Routes>
                  <Route path='/' element={<Users />} />
                  <Route path='/vector' element={<Vector />} />
                </Routes>
              </div>
            </BrowserRouter>
          </div>
          <footer className='sm:flex justify-center items-center hidden bottom-0 h-[6vh]  text-white  w-full  float-left text-center bg-[#0c3021] border-solid border-t-[1px] border-[#38F6A722]' >
            <p className='mr-0 sm:mr-[23rem] whitespace-nowrap'>

              قدرت گرفته از موتور هوشمند &nbsp;
              <a className='text-[#38F6A7]' href='https://avir.co.com' target='_blank'>
                آداک ویرا ایرانیان رهجو
              </a>
            </p>
          </footer>
        </div>
      )
    }
    else {
      return (
        <div className="App font-iransans">
          <div className='flex flex-col md:flex-row w-screen h-[94vh]'>
            {/*sidebar*/}
            <Toaster containerStyle={{ position: 'absolute' }} />
            <BrowserRouter>
              <div className='hidden sm:block'>
                <Sidenav />
              </div>
              <Routes>
                <Route path='/about' element={<About />} />
                <Route path='/history' element={<Sidenav />} />
                <Route path='/:id' element={<Chat />} />
                <Route path='/' element={<Home />} />
              </Routes>
            </BrowserRouter>
          </div>
          <footer className='sm:flex justify-center items-center hidden bottom-0 h-[6vh]  text-white  w-full  float-left text-center bg-[#0c3021] border-solid border-t-[1px] border-[#38F6A722]' >
            <p className='mr-0 sm:mr-[23rem] whitespace-nowrap'>

              قدرت گرفته از موتور هوشمند &nbsp;
              <a className='text-[#38F6A7]' href='https://avir.co.com' target='_blank'>
                آداک ویرا ایرانیان رهجو (آویر)
              </a>
            </p>
          </footer>
        </div>

      );
    }
  else
    return <BrowserRouter>
      <Toaster containerStyle={{ position: 'absolute' }} />
      <Routes>
        <Route path="/*" element={<Login />} />
      </Routes>
    </BrowserRouter>
}

export default App;
