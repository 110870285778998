import { DocumentTextIcon, UsersIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import { applicationLogo } from '../../api/utility';
// import { UsersIcon, DocumentTextIcon } from '@heroicons/react/outline'; // Importing icons from HeroIcons

export default function AdminSideNav() {
  const menus = [
    { title: 'کاربران', link: '/', icon: <UsersIcon className="h-6 w-6 text-white" /> },
    { title: 'vector', link: '/vector', icon: <DocumentTextIcon className="h-6 w-6 text-white" /> },
  ];

  return (
    <div className="min-h-screen  text-white flex flex-col">
      {/* Logo Section */}
      <div className="flex items-center justify-center py-8 bg-blue-950">
        <img src={applicationLogo} alt="Logo" className="h-12 bg-white" /> {/* Placeholder for logo */}
      </div>

      {/* Links Section */}
      <nav className="flex flex-col justify-start items-start px-6 pt-9 gap-4 w-full">
        {menus.map((item, i) => (
          <Link
            key={i}
            to={item.link}
            className="flex items-center w-full p-4 bg-green-950 hover:bg-gray-600 rounded-lg transition-all duration-300 ease-in-out"
          >
            {item.icon}
            <span className="mr-3 text-lg">{item.title}</span>
          </Link>
        ))}
        <button
          className='flex items-center w-full p-4 bg-green-950 hover:bg-gray-600 rounded-lg transition-all duration-300 ease-in-out'
          onClick={() => { localStorage.clear(); window.location.reload() }}>
          <ArrowRightStartOnRectangleIcon width={24} />
          <span className="mr-3 text-lg">خروج</span>
        </button>
      </nav>
    </div>
  );
}