import React, { useEffect, useState } from 'react'
import vira from '../../assets/images/vira.png';
import BotFace from './BotFace';

export default function Vira({ isSpeaking }) {
    
    return (
        <BotFace isSpeaking={isSpeaking} />
        // <></>
    )
}
