import React, { useEffect, useState } from 'react'
import { createVector, getUsers, getVectors } from '../../api'
import toast from 'react-hot-toast'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import Modal from '../common/Modal'

export default function Vector() {
    const [vectors, setVectors] = useState([])
    const [files, setFiles] = useState([])
    const [showModal, setShowModal] = useState(false)


    const handler = async () => {
        const { data, error } = await getVectors();
        if (data) {
            setVectors(data)
        }
        else {

        }
    }
    const save = async () => {
        // console.info(form)
        // return

        if (files.length === 0) {
            toast.error('فایلی انتخاب نشده است')
            return;
        }
        const l = toast.loading('در حال پردازش')
        const { data, error } = await createVector(files);
        if (data) {
            setShowModal(false)
            setFiles([])
            handler()
            toast.success('فایل شما موفقیت اپلود شد')
        }
        else {
            toast.error(error.response.data.message)
        }
        toast.dismiss(l)

    }

    useEffect(() => {
        if (!showModal) {
            handler()
            setFiles([])
        }
        else {
        }
        return () => {

        }
    }, [showModal])



    return (
        <>
            <div className="container overflow-x-auto">
                <h1 className=' text-white pb-4 mb-2 h-9 flex items-center justify-start rounded-md'>فایل‌های دیتابیس</h1>
                <section className='bg-[#cccccc3b] p-5 rounded-md overflow-auto px-5'>
                    <div className='w-full flex items-start mb-4  pb-5'>
                        <button className='text-sm text-white bg-btn rounded-md w-24 p-2 flex flex-row gap-2'
                            onClick={() => setShowModal(true)}
                        >
                            <PlusCircleIcon className='w-5' />
                            افزودن</button>
                    </div>
                    <table className="min-w-full divide-y divide-[#cccccc39] text-right rounded-md">
                        <thead className="text-white   rounded-md">
                            <tr className=' rounded-md'>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    شناسه
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    فایل‌ها
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-[#ffffff9c]  uppercase tracking-wider">
                                    وضعیت
                                </th>
                            </tr>
                        </thead>
                        <tbody className=" divide-y divide-[#cccccc39]">
                            {vectors?.map((vector) => (
                                <tr key={vector.id} className='hover:bg-[#cccccc2d] transition-all py-5'>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{vector.id}</div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{vector?.file_paths?.join(',')}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-white">{vector.status}</div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
            <Modal showModal={showModal} className={'w-[40rem]'} setShowModal={setShowModal} title={'ایجاد'} >
                <div className='mb-4 grid grid-cols-2 gap-2' >

                    <div className='flex flex-col justify-start items-start mt-4'>
                        <label>فایل‌های خود را انتخاب کنید</label>
                        <input type='file' multiple
                            className='border p-2 bg-input text-input-text text-white rounded-md focus:border-[#191130]'
                            onChange={e => {
                                setFiles(e.target.files)
                            }} />
                    </div>




                </div>
                <button className='float-left bg-btn text-white text-sm w-24 p-2 rounded-md' onClick={save}>ثبت</button>
            </Modal>

        </>
    );
}
