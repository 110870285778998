import axios from "axios";
import { baseURL, instance } from "./utility";

const callApi = async (config) => {
    try {
        const { data } = await instance.request(config)
        return { data }
    }
    catch (error) {
        return { error }
    }
}
const me = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: baseURL + '/user/me',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    };
    try {
        const { data } = await axios.request(config)
        return { data }
    }
    catch (error) {
        return { error }
    }
}

const login = async ({ username, password }) => {
    let data = {
        username,
        password
    };

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/token',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
        data: data
    };

    const response = await callApi(config)
    if (response.data)
        localStorage.setItem('token', response.data.access_token)
    return response


}
const getChats = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: '/chat/all-r',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const getChat = (id) => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/chat/${id}`,
        headers: {
            'Accept': 'application/json'
        }
    };

    return callApi(config)

}

const createChat = (data) => {
    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `/chat/create`,
        headers: {
            'Accept': 'application/json'
        },
        data
    };

    return callApi(config)
}

const deleteChat = (id) => {
    let config = {
        method: 'DELETE',
        maxBodyLength: Infinity,
        url: `/chat/${id}`,
        headers: {
            'Accept': 'application/json'
        }
    };

    return callApi(config)
}


const getUsers = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: '/admin/user/all',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const getUser = (id) => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/admin/user/${id}`,
        headers: {
            'Accept': 'application/json'
        }
    };

    return callApi(config)

}

const addUser = (data) => {
    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `/admin/signup`,
        headers: {
            'Accept': 'application/json'
        },
        data
    };

    return callApi(config)
}
const setUserAdministration = (id, isAdmin) => {
    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `/admin/user/${id}/set-admin/${isAdmin}`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}


const setUserActivation = (id, isActive) => {
    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `/admin/user/${id}/set-active/${isActive}`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}


const updateUser = (data) => {
    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `/admin/user/update`,
        headers: {
            'Accept': 'application/json'
        },
        data
    };

    return callApi(config)
}

const getLogs = ({ n }) => {
    const params = new URLSearchParams();
    if (n)
        params.append('n', n)
    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: `/log`,
        headers: {
            'Accept': 'application/json'
        },
        params,
    };

    return callApi(config)
}
const clearLogs = () => {
    let config = {
        method: 'DELETE',
        maxBodyLength: Infinity,
        url: `/log`,
        headers: {
            'Accept': 'application/json'
        },

    };

    return callApi(config)
}

const getVectors = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: '/vs',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const createVector = async (files) => {
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
    }

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/vs',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}
export {
    me,
    login,

    getChats,
    getChat,
    createChat,
    deleteChat,

    getUsers,
    getUser,
    setUserAdministration,
    setUserActivation,
    addUser,
    updateUser,

    getVectors,
    createVector,
    
    getLogs,
    clearLogs,
}